/**
 * @namespace TechniqueService
 * @desc Manejo de catalogo de tecnicos.
 * @memberOf Factories
 * @param {Object} $http Peticiones
 * @return {Object} service
 */
function TechniqueService($http) {
	/**
	 * Funciones disponibles del servicio
	 * @memberof Factories.TechniqueService
	 * @property {function} getTechnique
	 * @property {function} enableTechnique
	 */
	var service = {
		getTechniqueList: getTechniqueList,
		enableTechnique: enableTechnique

	};
	/* Regresa la definicion del servicio */
	return service;

	/* Implementacion */
	/**
	 * @static
	 * @function getCustomerComplete
	 * @desc Procesa respuesta de servicio.
	 * @memberof Factories.CraneService
	 * @param      {Object}  data  	Response de servicio.
	 * @return     {Object}  data  Objeto data contenido en el response
	 */
	function getCustomerComplete(data) {
		return data.data;
	}
	/**
	 * @function enableTechnique
	 * @desc Libera la grúa.
	 * @memberof FactoriesTechniqueService
	 * @param      {Object}  provider  	Id de proveedor.
	 * @param      {Object}  idservice  id de asistencia.
	 * @return     {Object}  response  llamada del servicio.
	 */
	function enableTechnique(provider, idservice) {
		return $http.get('api/v1/providers/' + provider + '/assistances/' + idservice + '/enable_crane?requestFrom=portal')
			.then(getCustomerComplete);
	}
	/**
	 * @function getTechniqueList
	 * @desc Obtiene lista de gruas para mostrar en el catalogo con datos actualizados.
	 * @memberof Factories.TechnicianService
	 * @param      {Object}  provider  	Id de proveedor.
	 * @return     {Object}  response  Lista de gruas y atributos.
	 */
	function getTechniqueList(provider) {
		return $http.get('api/v1/providers/' + provider + '/technicianList')
			.then(getCustomerComplete);
	}

}

angular
	.module('app')
	.service('TechnicianService', TechniqueService);
