/**
 * Componente de vista para mostar datos genericos en las asistencias entrantes de hogar.
 * @module wrapperGenericController
 * @return {Object} ctrl
 */
function homeDetailServiceController() {
}

angular
  .module('app')
  .component('homeDetailService', {
    templateUrl: 'app/components/homeDetailService/homeDetailService.html',
    controller: homeDetailServiceController,
    controllerAs : 'ctrl',
		bindings: {
			service : '<',
			subservice: '<',
			origin:'<',
			pevent:'<',
			appointment: '<',
			date : '<'

		}
  });

